// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as PfIcon from "../packages/pf-icon/src/PfIcon.bs.js";
import * as ReactUtils from "../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as EditorDrawer from "../shared/components/EditorDrawer.bs.js";
import * as Notifications__List from "./components/Notifications__List.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.Notifications__Root";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function Notifications__Root(Props) {
  var wrapperClasses = Props.wrapperClasses;
  var buttonClasses = Props.buttonClasses;
  var iconClasses = Props.iconClasses;
  var hasNotifications = Props.hasNotifications;
  var title = Props.title;
  var match = React.useState(function () {
        return false;
      });
  var setShowNotifications = match[1];
  return React.createElement("div", {
              className: wrapperClasses
            }, ReactUtils.nullUnless(React.createElement(EditorDrawer.make, {
                      closeDrawerCB: (function (param) {
                          Curry._1(setShowNotifications, (function (param) {
                                  return false;
                                }));
                        }),
                      closeButtonTitle: tr(undefined, undefined, "close") + Belt_Option.getWithDefault(title, ""),
                      size: /* Small */0,
                      children: React.createElement(Notifications__List.make, {})
                    }), match[0]), React.createElement("button", {
                  className: buttonClasses,
                  title: tr(undefined, undefined, "show_notifications"),
                  onClick: (function (param) {
                      Curry._1(setShowNotifications, (function (param) {
                              return true;
                            }));
                    })
                }, React.createElement("span", {
                      className: "relative"
                    }, React.createElement(PfIcon.make, {
                          className: "if i-bell-light if-fw text-xl"
                        }), ReactUtils.nullUnless(React.createElement("span", {
                              className: iconClasses
                            }), hasNotifications)), Belt_Option.getWithDefault(title, "")));
}

var make = Notifications__Root;

export {
  str ,
  tr ,
  make ,
}
/* I18n Not a pure module */
