// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function Spread(Props) {
  return React.cloneElement(Props.children, Props.props);
}

var make = Spread;

export {
  make ,
}
/* react Not a pure module */
