// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function StandingShield(Props) {
  var color = Props.color;
  var sizeClass = Props.sizeClass;
  return React.createElement("svg", {
              className: sizeClass,
              fill: color,
              viewBox: "0 0 49 49",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  className: "opacity-75",
                  d: "M7.83377 11.1092L24.3192 4.25812C24.5648 4.15603 24.8411 4.15603 25.0867 4.25812L41.5721 11.1092C41.9452 11.2643 42.1884 11.6267 42.1884 12.0308V23.4764C42.1884 29.045 36.0992 38.8206 25.1233 43.8751C24.8583 43.9971 24.5481 43.9924 24.2858 43.8646C12.3439 38.0435 7.21753 29.0512 7.21753 23.4764V12.0308C7.21753 11.6267 7.46067 11.2643 7.83377 11.1092Z",
                  fill: color
                }), React.createElement("path", {
                  d: "M11.4346 13.229L24.3193 7.87433C24.5649 7.77224 24.8412 7.77224 25.0868 7.87433L37.9715 13.229C38.3446 13.3841 38.5877 13.7478 38.5877 14.1518V23.1022C38.5877 27.5095 33.7843 35.2355 25.1228 39.2605C24.8582 39.3834 24.5485 39.3788 24.2866 39.2502C14.8647 34.6267 10.8184 27.5157 10.8184 23.1022V14.1518C10.8184 13.7478 11.0615 13.3841 11.4346 13.229Z",
                  fill: color
                }));
}

var make = StandingShield;

export {
  make ,
}
/* react Not a pure module */
