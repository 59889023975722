// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";

function onWindowClick(showDropdown, setShowDropdown, _event) {
  if (showDropdown) {
    return Curry._1(setShowDropdown, (function (param) {
                  return false;
                }));
  }
  
}

function toggleDropdown(setShowDropdown, $$event) {
  $$event.stopPropagation();
  return Curry._1(setShowDropdown, (function (showDropdown) {
                return !showDropdown;
              }));
}

function containerClasses(className) {
  return "dropdown inline-block relative text-sm " + className;
}

function Dropdown(Props) {
  var selected = Props.selected;
  var contents = Props.contents;
  var rightOpt = Props.right;
  var classNameOpt = Props.className;
  var right = rightOpt !== undefined ? rightOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "w-full md:w-auto";
  var match = React.useState(function () {
        return false;
      });
  var setShowDropdown = match[1];
  var showDropdown = match[0];
  React.useEffect((function () {
          var curriedFunction = function (param) {
            return onWindowClick(showDropdown, setShowDropdown, param);
          };
          var removeEventListener = function (param) {
            window.removeEventListener("click", curriedFunction);
          };
          if (showDropdown) {
            window.addEventListener("click", curriedFunction);
            return removeEventListener;
          } else {
            removeEventListener(undefined);
            return ;
          }
        }), [showDropdown]);
  return React.createElement("div", {
              className: "dropdown inline-block relative text-sm " + className,
              onClick: (function (param) {
                  return toggleDropdown(setShowDropdown, param);
                })
            }, selected, showDropdown ? React.createElement("div", {
                    className: "dropdown__list bg-white shadow-lg rounded mt-1 border border-gray-300 divide-y divide-gray-50 absolute overflow-x-hidden z-30 " + (
                      right ? "end-0" : "start-0"
                    )
                  }, $$Array.mapi((function (index, content) {
                          return React.createElement("div", {
                                      key: "dropdown-" + String(index),
                                      className: "cursor-pointer block text-sm font-medium text-gray-900 bg-white hover:text-primary-500 hover:bg-gray-50 focus-within:outline-none focus-within:bg-gray-50 focus-within:text-primary-500"
                                    }, content);
                        }), contents)) : null);
}

var make = Dropdown;

export {
  onWindowClick ,
  toggleDropdown ,
  containerClasses ,
  make ,
}
/* react Not a pure module */
