// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "./I18n.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ArrayUtils from "./ArrayUtils.bs.js";
import * as ReactUtils from "./ReactUtils.bs.js";
import * as LoadingSpinner from "../components/LoadingSpinner.bs.js";
import * as SkeletonLoading from "../components/SkeletonLoading.bs.js";
import NotFoundSvg from "./assets/not-found.svg";

var notFoundSVG = NotFoundSvg;

var partial_arg = "components.Pagination";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function Make(Item) {
  var toArray = function (t) {
    if (typeof t === "number") {
      return [];
    } else {
      return t._0;
    }
  };
  var length = function (t) {
    if (typeof t === "number") {
      return 0;
    } else {
      return t._0.length;
    }
  };
  var getCursor = function (t) {
    if (typeof t === "number" || t.TAG !== /* PartiallyLoaded */0) {
      return ;
    } else {
      return t._1;
    }
  };
  var update = function (t, fn) {
    if (typeof t === "number") {
      return /* Unloaded */0;
    } else if (t.TAG === /* PartiallyLoaded */0) {
      return {
              TAG: /* PartiallyLoaded */0,
              _0: Curry._1(fn, t._0),
              _1: t._1
            };
    } else {
      return {
              TAG: /* FullyLoaded */1,
              _0: Curry._1(fn, t._0)
            };
    }
  };
  var make = function (items, hasNextPage, endCursor) {
    if (hasNextPage && endCursor !== undefined) {
      return {
              TAG: /* PartiallyLoaded */0,
              _0: items,
              _1: endCursor
            };
    } else {
      return {
              TAG: /* FullyLoaded */1,
              _0: items
            };
    }
  };
  var showLoading = function (t, loading) {
    if (typeof t === "number") {
      return null;
    }
    var isLoading = loading ? ArrayUtils.isNotEmpty(loading._0) : false;
    return React.createElement(LoadingSpinner.make, {
                loading: isLoading
              });
  };
  var showStats = function (totalCount, loadedCount, singularName, pluralName) {
    return React.createElement("div", {
                className: "pt-8 pb-4 mx-auto text-gray-800 text-xs px-2 text-center font-semibold"
              }, totalCount === loadedCount ? t([
                      [
                        "singular_name",
                        singularName
                      ],
                      [
                        "plural_name",
                        pluralName
                      ]
                    ], totalCount, "fully_loaded_text") : t([
                      [
                        "total",
                        String(totalCount)
                      ],
                      [
                        "loaded",
                        String(loadedCount)
                      ],
                      [
                        "plural_name",
                        pluralName
                      ]
                    ], undefined, "partially_loaded_text"));
  };
  var renderEntries = function (entries, emptyMessage, totalEntriesCount, entriesView, singularResourceName, pluralResourceName) {
    return React.createElement("div", {
                className: "w-full"
              }, ArrayUtils.isEmpty(entries) ? React.createElement("div", {
                      className: "flex flex-col mx-auto bg-white rounded-md border p-6 justify-center items-center"
                    }, React.createElement("img", {
                          className: "w-30 h-30",
                          alt: t(undefined, undefined, "not_found_alt"),
                          src: notFoundSVG
                        }), React.createElement("h4", {
                          className: "mt-3 text-base md:text-lg text-center font-semibold"
                        }, emptyMessage)) : Curry._1(entriesView, entries), showStats(totalEntriesCount, entries.length, singularResourceName, pluralResourceName));
  };
  var renderView = function (pagedItems, loading, emptyMessage, entriesView, totalEntriesCount, loadMore, singularResourceName, pluralResourceName) {
    var tmp;
    tmp = typeof pagedItems === "number" ? React.createElement("div", undefined, SkeletonLoading.multiple(4, SkeletonLoading.card(undefined, undefined))) : (
        pagedItems.TAG === /* PartiallyLoaded */0 ? React.createElement("div", undefined, renderEntries(pagedItems._0, emptyMessage, totalEntriesCount, entriesView, singularResourceName, pluralResourceName), loading ? ReactUtils.nullUnless(Curry._1(loadMore, pagedItems._1), ArrayUtils.isEmpty(loading._0)) : React.createElement("div", undefined, SkeletonLoading.multiple(1, SkeletonLoading.card(undefined, undefined)))) : renderEntries(pagedItems._0, emptyMessage, totalEntriesCount, entriesView, singularResourceName, pluralResourceName)
      );
    return React.createElement("div", undefined, tmp, showLoading(pagedItems, loading));
  };
  return {
          toArray: toArray,
          length: length,
          getCursor: getCursor,
          update: update,
          make: make,
          showLoading: showLoading,
          showStats: showStats,
          renderEntries: renderEntries,
          renderView: renderView
        };
}

export {
  notFoundSVG ,
  t ,
  Make ,
}
/* notFoundSVG Not a pure module */
