// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as Icon from "../../shared/Icon.bs.js";
import * as User from "../../shared/types/User.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../shared/Avatar.bs.js";
import * as DateFns from "../../shared/utils/DateFns.bs.js";
import * as Tooltip from "../../shared/components/Tooltip.bs.js";
import * as Js_promise from "../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as ReactUtils from "../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as GraphqlQuery from "../../shared/utils/GraphqlQuery.bs.js";
import * as Notifications__Entry from "../types/Notifications__Entry.bs.js";

import "./Notifications__EntryCard.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.Notifications__EntryCard";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation MarkNotificationMutation($notificationId: ID!)  {\nmarkNotification(notificationId: $notificationId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.markNotification;
  return {
          markNotification: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.markNotification;
  var value$2 = value$1.success;
  var markNotification = {
    success: value$2
  };
  return {
          markNotification: markNotification
        };
}

function serializeVariables(inp) {
  return {
          notificationId: inp.notificationId
        };
}

function makeVariables(notificationId, param) {
  return {
          notificationId: notificationId
        };
}

var MarkNotificationQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var MarkNotificationQuery_Graphql_error = include.Graphql_error;

var MarkNotificationQuery_decodeNotification = include.decodeNotification;

var MarkNotificationQuery_decodeObject = include.decodeObject;

var MarkNotificationQuery_decodeNotifications = include.decodeNotifications;

var MarkNotificationQuery_decodeErrors = include.decodeErrors;

var MarkNotificationQuery_flashNotifications = include.flashNotifications;

var MarkNotificationQuery_sendQuery = include.sendQuery;

var MarkNotificationQuery_query = include.query;

var MarkNotificationQuery_make = include.make;

var MarkNotificationQuery = {
  MarkNotificationQuery_inner: MarkNotificationQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: MarkNotificationQuery_Graphql_error,
  decodeNotification: MarkNotificationQuery_decodeNotification,
  decodeObject: MarkNotificationQuery_decodeObject,
  decodeNotifications: MarkNotificationQuery_decodeNotifications,
  decodeErrors: MarkNotificationQuery_decodeErrors,
  flashNotifications: MarkNotificationQuery_flashNotifications,
  sendQuery: MarkNotificationQuery_sendQuery,
  query: MarkNotificationQuery_query,
  $$fetch: $$fetch,
  make: MarkNotificationQuery_make
};

function markNotification(notificationId, setSaving, markNotificationCB, $$event) {
  $$event.preventDefault();
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  Js_promise.$$catch((function (param) {
          Curry._1(setSaving, (function (param) {
                  return false;
                }));
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (response) {
              if (response.markNotification.success) {
                Curry._1(setSaving, (function (param) {
                        return false;
                      }));
                Curry._1(markNotificationCB, notificationId);
              } else {
                Curry._1(setSaving, (function (param) {
                        return false;
                      }));
              }
              return Promise.resolve(undefined);
            }), Curry._3($$fetch, undefined, undefined, {
                notificationId: notificationId
              })));
}

function avatarClasses(size) {
  var mdSize = size[1];
  var defaultSize = size[0];
  return "w-" + (defaultSize + (" h-" + (defaultSize + (" md:w-" + (mdSize + (" md:h-" + (mdSize + " text-xs border border-white rounded-full overflow-hidden shrink-0 object-cover")))))));
}

function avatar(sizeOpt, avatarUrl, name) {
  var size = sizeOpt !== undefined ? sizeOpt : [
      "10",
      "10"
    ];
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses(size),
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses(size)
              });
  }
}

function Notifications__EntryCard(Props) {
  var entry = Props.entry;
  var markNotificationCB = Props.markNotificationCB;
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var _readAt = Notifications__Entry.readAt(entry);
  var actor = Notifications__Entry.actor(entry);
  var partial_arg = Notifications__Entry.id(entry);
  return React.createElement("div", {
              key: Notifications__Entry.id(entry),
              "aria-label": "Notification " + Notifications__Entry.id(entry),
              className: "notifications__entry-card block relative py-5 px-4 lg:px-8 text-sm font-medium hover:bg-gray-50 focus-within:bg-gray-50 transition ease-in-out duration-150 " + (
                _readAt !== undefined ? "notifications__entry-card--read text-gray-600" : "notifications__entry-card--unread"
              )
            }, React.createElement("div", {
                  className: "flex justify-between items-center"
                }, React.createElement("div", {
                      className: "flex-1 flex items-center relative"
                    }, React.createElement("div", {
                          className: "shrink-0 inline-block relative"
                        }, actor !== undefined ? avatar(undefined, User.avatarUrl(actor), User.name(actor)) : null, ReactUtils.nullUnless(React.createElement("span", {
                                  className: "notifications__entry-unread-dot absolute top-0 flex justify-center h-full items-center"
                                }, React.createElement("span", {
                                      className: "block h-1 w-1 rounded-full shadow-solid bg-primary-400"
                                    })), Belt_Option.isNone(Notifications__Entry.readAt(entry)))), Belt_Option.isSome(Notifications__Entry.notifiableId(entry)) ? React.createElement("a", {
                            className: "ms-4 outline-none",
                            href: "/notifications/" + Notifications__Entry.id(entry)
                          }, Notifications__Entry.message(entry)) : React.createElement("div", {
                            className: "ms-4"
                          }, Notifications__Entry.message(entry))), React.createElement("div", {
                      className: "shrink-0"
                    }, React.createElement("span", {
                          className: "notifications__entry-card-time block text-xs text-gray-400"
                        }, React.createElement("span", {
                              className: "hidden md:inline-block md:ps-4"
                            }, DateFns.format(Notifications__Entry.createdAt(entry), "HH:mm"))), React.createElement("div", {
                          className: "opacity-0 notifications__entry-card-buttons absolute top-0 bottom-0 end-0 flex items-center ps-4 pe-4 md:pe-8 transition ease-in-out duration-150"
                        }, ReactUtils.nullIf(React.createElement(Tooltip.make, {
                                  tip: t(undefined, undefined, "mark_read"),
                                  position: "Start",
                                  children: React.createElement("button", {
                                        "aria-label": t(undefined, undefined, "mark_read"),
                                        className: "flex justify-center items-center w-8 h-8 font-semibold p-2 md:py-1 border border-gray-300 rounded text-sm bg-white text-gray-600 hover:text-primary-500 hover:border-primary-400 hover:bg-gray-50 hover:shadow-md focus:outline-none focus:text-primary-500 focus:border-primary-400 focus:bg-gray-50 focus:shadow-md transition ease-in-out duration-150",
                                        title: t(undefined, undefined, "mark_read"),
                                        disabled: match[0],
                                        onClick: (function (param) {
                                            return markNotification(partial_arg, setSaving, markNotificationCB, param);
                                          })
                                      }, React.createElement(Icon.make, {
                                            className: "if i-check-solid"
                                          }))
                                }), Belt_Option.isSome(Notifications__Entry.readAt(entry)))))));
}

var make = Notifications__EntryCard;

export {
  str ,
  t ,
  MarkNotificationQuery ,
  markNotification ,
  avatarClasses ,
  avatar ,
  make ,
}
/*  Not a pure module */
